import "../scss/frontend.scss";
import "./custom-add-to-cart-ajax.js";
import "./archive.js";

window.addEventListener("load", function () {
	// Add active class to payment method
	jQuery("body").on("updated_checkout", function () {
		var $input = $(".wc_payment_method input");
		$input.filter(":checked").parent().addClass("active");
		$input.on("change", function () {
			// removing and adding our .is-active whnever there is a change
			$input.parent().removeClass("active");
			$(this).parent().addClass("active");
		});
	});
});
