import $ from "jquery";

jQuery(function ($) {
	$(".ajax_add_to_cart_form").on("submit", function (e) {
		e.preventDefault();

		var form = $(this);
		var addToCartButton = form.find(".single_add_to_cart_button");
		var product_id = form.find('input[name="add-to-cart"]').val();
		var variation_id = form.find('input[name="variation_id"]').val();
		var quantity = form.find('input[name="quantity"]').val() || 1;

		addToCartButton.addClass("loading");

		$.ajax({
			type: "POST",
			url: ajax_add_to_cart_params.ajax_url,
			data: {
				action: "ajax_add_to_cart",
				product_id: product_id,
				variation_id: variation_id,
				quantity: quantity,
			},
			success: function (response) {
				addToCartButton.removeClass("loading");

				if (response.success) {
					addToCartButton.addClass("added");

					if (form.find(".added_to_cart").length === 0) {
						form.append(
							'<a href="' +
								ajax_add_to_cart_params.cart_url +
								'" class="added_to_cart wc-forward" style="margin-top: 10px;">' +
								response.cart_button_text +
								"</a>",
						);
					}
				}
			},
			error: function () {
				addToCartButton.removeClass("loading");
				alert("Failed to add product to cart.");
			},
		});
	});
});
