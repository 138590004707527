function termDescriptionOverflow() {
	const termDescription = document.querySelector(".term-description");

	if (
		termDescription &&
		termDescription.scrollHeight > termDescription.clientHeight
	) {
		const overlay = document.createElement("div");
		overlay.classList.add("term-description__overlay");
		overlay.innerHTML = '<div class="show-more">Näytä enemmän</div>';
		termDescription.appendChild(overlay);

		const showMore = document.querySelector(".show-more");

		if (!showMore) {
			return;
		}

		showMore.addEventListener("click", function () {
			termDescription.classList.toggle("show-all");
			showMore.classList.toggle("open");
			if ((document.querySelector(".show-more").innerText = "Näytä enemmän")) {
				document.querySelector(".show-more").innerText = "Näytä vähemmän";
			} else {
				document.querySelector(".show-more").innerText = "Näytä enemmän";
			}
		});
	}
}

function toggleFiltersMobile() {
	const filterButton = document.querySelector(".open-filters-mobile");
	const closeFiltersButton = document.querySelector(".close-filters");
	const filterContainer = document.querySelector(".facets");
	const filterOverlay = document.querySelector(".facets-overlay");

	if (
		!filterButton ||
		!closeFiltersButton ||
		!filterContainer ||
		!filterOverlay
	) {
		return;
	}

	filterButton.addEventListener("click", function () {
		filterContainer.classList.add("open");
		filterOverlay.classList.add("open");
	});

	closeFiltersButton.addEventListener("click", function () {
		filterContainer.classList.remove("open");
		filterOverlay.classList.remove("open");
	});
}

function toggleFacets() {
	const labels = document.querySelectorAll(".facet-label");

	if (labels.length === 0) {
		return;
	}

	labels.forEach((label) => {
		label.addEventListener("click", function () {
			this.classList.toggle("open");
			label.nextElementSibling.classList.toggle("open");
		});
	});
}

window.addEventListener("load", function () {
	termDescriptionOverflow();
});

jQuery(document).on("facetwp-loaded", function () {
	toggleFacets();
	toggleFiltersMobile();
});
